import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      redirect: '/home',
      component: () => import('@/view/layout/Layout'),
      children: [
        {
          path: '/home',
          name: 'home',
          // component: () => import("@/view/pages/Dashboard.vue")
          //component: () => import("@/view/pages/Home.vue"),
          component: () => import('@/view/pages/NewHome.vue'),
        },
        {
          path: '/dashboard',
          name: 'dashboard',
          //component: () => import("@/view/pages/Dashboard.vue"),
          component: () => import('@/view/pages/NewDashboard.vue'),
        },
        {
          path: '/newdashboard',
          name: 'newdashboard',
          component: () => import('@/view/pages/NewDashboard.vue'),
        },
        {
          path: '/g-drive',
          name: 'google drive',
          component: () => import('@/view/pages/GoogleDrive.vue'),
        },
        // {
        //   path: "/dashboard",
        //   name: "dashboard",
        //   component: () => import("@/view/pages/dashboard/page.vue"),
        //   children: [
        //     // Route Dashboard
        //     {
        //       path: "kondisi_pabrik",
        //       name: "Kondisi Pabrik",
        //       component: () => import("@/view/pages/dashboard/KondisiPabrik.vue")
        //     },
        //     {
        //       path: "kondisi_plant",
        //       name: "Kondisi Plant",
        //       component: () => import("@/view/pages/dashboard/KondisiPlant.vue")
        //     },
        //     {
        //       path: "kondisi_section",
        //       name: "Kondisi Section",
        //       component: () => import("@/view/pages/dashboard/KondisiSection.vue")
        //     },
        //     {
        //       path: "informasi_equipment",
        //       name: "Informasi Equipment",
        //       component: () => import("@/view/pages/dashboard/InformasiEquipment.vue")
        //     },
        //     {
        //       path: "informasi_custom_user",
        //       name: "Informasi Custom User",
        //       component: () => import("@/view/pages/dashboard/InformasiCustomUser.vue")
        //     },
        //     {
        //       path: "inspection_plan",
        //       name: "Dashbord Inspection Plan",
        //       component: () => import("@/view/pages/dashboard/InspectionPlan.vue")
        //     },
        //   ]
        // },
        {
          path: '/report',
          name: 'Report',
          component: () => import('@/view/pages/report/page.vue'),
          children: [
            // Route Report
            {
              path: 'index',
              name: 'Create Report',
              component: () => import('@/view/pages/report/create/create.vue'),
            },
            {
              path: 'create/report',
              name: 'Add Report',
              component: () => import('@/view/pages/report/create/addReport.vue'),
            },
            {
              path: 'edit/report/:id',
              name: 'Edit Report',
              component: () => import('@/view/pages/report/create/editReport.vue'),
            },
            {
              path: 'approval/:id',
              name: 'Approval Report',
              component: () => import('@/view/pages/report/create/approvalReport.vue'),
            },
            {
              path: 'create/record',
              name: 'Add Record',
              component: () => import('@/view/pages/report/create/addRecord.vue'),
            },
            {
              path: 'edit/record/:id',
              name: 'Edit Record',
              component: () => import('@/view/pages/report/create/editRecord.vue'),
            },

            // Conditional Report
            {
              path: 'conditional_report',
              name: 'Conditional Report',
              component: () =>
                import('@/view/pages/report/conditional_report/conditional_report.vue'),
            },
            {
              path: 'conditional_report/add',
              name: 'Add Conditional Report',
              component: () =>
                import('@/view/pages/report/conditional_report/addConditionalReport.vue'),
            },
            {
              path: 'conditional_report/edit/:id',
              name: 'Edit Conditional Report',
              component: () =>
                import('@/view/pages/report/conditional_report/editConditionalReport.vue'),
            },
          ],
        },

        // Penilaian resiko
        {
          path: 'equipment_kritis',
          name: 'Penilaian Resiko',
          component: () => import('@/view/pages/master/equipment_kritis/equipment_kritis.vue'),
        },
        {
          path: 'equipment_kritis/add',
          name: 'Add Penilaian Resiko',
          component: () => import('@/view/pages/master/equipment_kritis/addEquipmentKritis.vue'),
        },
        {
          path: 'equipment_kritis/edit/:id',
          name: 'Edit Penilaian Resiko',
          component: () => import('@/view/pages/master/equipment_kritis/editEquipmentKritis.vue'),
        },

        // Data Desain
        {
          path: '/datadesain',
          name: 'Data Desain',
          component: () => import('@/view/pages/datadesain/DataDesain.vue'),
        },
        {
          path: '/datadesain/add',
          name: 'Create Data Desain',
          component: () => import('@/view/pages/datadesain/AddDataDesain.vue'),
        },
        {
          path: '/datadesain/inputdata',
          name: 'Input Data Desain',
          component: () => import('@/view/pages/datadesain/InputDataDesain.vue'),
        },
        {
          path: '/datadesain/edit/:id',
          name: 'Update Data Desain',
          component: () => import('@/view/pages/datadesain/EditDataDesain.vue'),
        },

        // Peralatan Inspeksi
        {
          path: '/peralataninspeksi',
          name: 'Peralatan Inspeksi',
          component: () => import('@/view/pages/peralataninspeksi/PeralatanInspeksi.vue'),
        },
        {
          path: '/peralataninspeksi/add',
          name: 'Create Peralatan Inspeksi',
          component: () => import('@/view/pages/peralataninspeksi/AddPeralatanInspeksi.vue'),
        },
        {
          path: '/peralataninspeksi/edit/:id',
          name: 'Update Peralatan Inspeksi',
          component: () => import('@/view/pages/peralataninspeksi/EditPeralatanInspeksi.vue'),
        },

        // Sertifikasi Perizinan
        {
          path: '/sertifikasiperizinan',
          name: 'Sertifikasi Perizinan',
          component: () => import('@/view/pages/sertifikasiperizinan/SertifikasiPerizinan.vue'),
        },
        {
          path: '/sertifikasiperizinan/add',
          name: 'Create Sertifikasi Perizinan',
          component: () => import('@/view/pages/sertifikasiperizinan/AddSertifikasiPerizinan.vue'),
        },
        {
          path: '/sertifikasiperizinan/edit/:id',
          name: 'Update Sertifikasi Perizinan',
          component: () => import('@/view/pages/sertifikasiperizinan/EditSertifikasiPerizinan.vue'),
        },
        // Inspection Plan
        {
          path: '/inspectionplan',
          name: 'Inspection Plan',
          component: () => import('@/view/pages/inspectionplan/InspectionPlan.vue'),
        },
        {
          path: '/inspectionplan/add/:tahun',
          name: 'Create Inspection Plan',
          component: () => import('@/view/pages/inspectionplan/AddInspectionPlan.vue'),
        },
        {
          path: '/inspectionplan/edit/:id',
          name: 'Edit Inspection Plan',
          component: () => import('@/view/pages/inspectionplan/EditInspectionPlan.vue'),
        },
        {
          path: '/inspectionplan/import/:tahun',
          name: 'Import Inspection Plan',
          component: () => import('@/view/pages//inspectionplan/importInspectionPlan.vue'),
        },
        // Todo
        {
          path: '/todo',
          name: 'To Do',
          component: () => import('@/view/pages/todo/todo.vue'),
        },

        // Pengujian & Kalibrasi
        {
          path: '/pengujiankalibrasi',
          name: 'Pengujian dan Kalibrasi',
          component: () => import('@/view/pages/pengujiankalibrasi/PengujianKalibrasi.vue'),
        },
        {
          path: '/pengujiankalibrasi/add',
          name: 'Create Pengujian dan Kalibrasi',
          component: () => import('@/view/pages/pengujiankalibrasi/AddPengujianKalibrasi.vue'),
        },
        {
          path: '/pengujiankalibrasi/edit/:id',
          name: 'Update Pengujian dan Kalibrasi',
          component: () => import('@/view/pages/pengujiankalibrasi/EditPengujianKalibrasi.vue'),
        },

        // Kualifikasi Las
        {
          path: '/kualifikasi_las',
          name: 'KualifikasiLas',
          component: () => import('@/view/pages/KualifikasiLas/page.vue'),
          children: [
            // Route Welder
            {
              path: 'welder',
              name: 'WELDER',
              component: () => import('@/view/pages/KualifikasiLas/welder/Welder.vue'),
            },
            {
              path: 'addwelder',
              name: 'Add WELDER',
              component: () => import('@/view/pages/KualifikasiLas/welder/addWelder.vue'),
            },
            {
              path: 'editwelder/:id',
              name: 'Edit WELDER',
              component: () => import('@/view/pages/KualifikasiLas/welder/editWelder.vue'),
            },

            // Route Kualifikasi Welder
            {
              path: 'kualifikasi_welder',
              name: 'Kualifikasi Welder',
              component: () =>
                import('@/view/pages/KualifikasiLas/kualifikasiwelder/KualifikasiWelder.vue'),
            },
            {
              path: 'addkualifikasiwelder',
              name: 'Add Kualifikasi Welder',
              component: () =>
                import('@/view/pages/KualifikasiLas/kualifikasiwelder/addKualifikasiWelder.vue'),
            },
            {
              path: 'editkualifikasiwelder/:id',
              name: 'Edit Kualifikasi Welder',
              component: () =>
                import('@/view/pages/KualifikasiLas/kualifikasiwelder/editKualifikasiWelder.vue'),
            },

            // Route History
            {
              path: 'history',
              name: 'History',
              component: () => import('@/view/pages/KualifikasiLas/History/History.vue'),
            },
            {
              path: 'addhistory',
              name: 'Add History',
              component: () => import('@/view/pages/KualifikasiLas/History/addHistory.vue'),
            },
            {
              path: 'edithistory/:id',
              name: 'Edit History',
              component: () => import('@/view/pages/KualifikasiLas/History/editHistory.vue'),
            },

            // Route PQR
            {
              path: 'pqr',
              name: 'PQR',
              component: () => import('@/view/pages/KualifikasiLas/pqr/PQR.vue'),
            },
            {
              path: 'addpqr',
              name: 'Add PQR',
              component: () => import('@/view/pages/KualifikasiLas/pqr/addPqr.vue'),
            },
            {
              path: 'editpqr/:id',
              name: 'Edit PQR',
              component: () => import('@/view/pages/KualifikasiLas/pqr/editPqr.vue'),
            },
            // Route WPS
            {
              path: 'wps',
              name: 'WPS',
              component: () => import('@/view/pages/KualifikasiLas/wps/WPS.vue'),
            },
            {
              path: 'addwps',
              name: 'Add WPS',
              component: () => import('@/view/pages/KualifikasiLas/wps/addWps.vue'),
            },
            {
              path: 'editwps/:id',
              name: 'Edit WPS',
              component: () => import('@/view/pages/KualifikasiLas/wps/editWps.vue'),
            },
          ],
        },

        // Data Master
        {
          path: '/master',
          name: 'Master',
          component: () => import('@/view/pages/master/page.vue'),
          children: [
            // Route Master Pabrik
            {
              path: 'pabrik',
              name: 'Pabrik',
              component: () => import('@/view/pages/master/pabrik/pabrik.vue'),
            },
            {
              path: 'addpabrik',
              name: 'Add Pabrik',
              component: () => import('@/view/pages/master/pabrik/addPabrik.vue'),
            },
            {
              path: 'editpabrik/:id',
              name: 'Edit Pabrik',
              component: () => import('@/view/pages/master/pabrik/editPabrik.vue'),
            },
            // Route Master Eksekutor
            {
              path: 'Eksekutor',
              name: 'Eksekutor',
              component: () => import('@/view/pages/master/eksekutor/Eksekutor.vue'),
            },
            {
              path: 'addeksekutor',
              name: 'Add Eksekutor',
              component: () => import('@/view/pages/master/eksekutor/addEksekutor.vue'),
            },
            {
              path: 'editeksekutor/:id',
              name: 'Edit Eksekutor',
              component: () => import('@/view/pages/master/eksekutor/editEksekutor.vue'),
            },

            // Route Master Plant
            {
              path: 'plant',
              name: 'Plant',
              component: () => import('@/view/pages/master/plant/plant.vue'),
            },
            {
              path: 'addplant',
              name: 'Add Plant',
              component: () => import('@/view/pages/master/plant/addPlant.vue'),
            },
            {
              path: 'editplant/:id',
              name: 'Edit Plant',
              component: () => import('@/view/pages/master/plant/editPlant.vue'),
            },

            // Route Master Part
            {
              path: 'part',
              name: 'Part',
              component: () => import('@/view/pages/master/part/part.vue'),
            },
            {
              path: 'addpart',
              name: 'Add Part',
              component: () => import('@/view/pages/master/part/addPart.vue'),
            },
            {
              path: 'editpart/:id',
              name: 'Edit Part',
              component: () => import('@/view/pages/master/part/editPart.vue'),
            },

            // Route Master Equipment
            {
              path: 'equipment',
              name: 'Equipment',
              component: () => import('@/view/pages/master/equipment/equipment.vue'),
            },
            {
              path: 'addequipment',
              name: 'Add Equipment',
              component: () => import('@/view/pages/master/equipment/addEquipment.vue'),
            },
            {
              path: 'importequipment',
              name: 'Import Equipment',
              component: () => import('@/view/pages/master/equipment/importEquipment.vue'),
            },
            {
              path: 'editequipment/:id',
              name: 'Edit Equipment',
              component: () => import('@/view/pages/master/equipment/editEquipment.vue'),
            },

            // Route Master Condition Monitoring
            {
              path: 'conditionmonitoring',
              name: 'Condition Monitoring',
              component: () =>
                import('@/view/pages/master/ConditionMonitoring/ConditionMonitoring.vue'),
            },
            {
              path: 'addconditionmonitoring',
              name: 'Add Condition Monitoring',
              component: () =>
                import('@/view/pages/master/ConditionMonitoring/addConditionMonitoring.vue'),
            },
            {
              path: 'editconditionmonitoring/:id',
              name: 'Edit Condition Monitoring',
              component: () =>
                import('@/view/pages/master/ConditionMonitoring/editConditionMonitoring.vue'),
            },

            // Route Master Group Point
            {
              path: 'grouppoint',
              name: 'Group Point',
              component: () => import('@/view/pages/master/GroupPoint/GroupPoint.vue'),
            },
            {
              path: 'addgrouppoint',
              name: 'Add Group Point',
              component: () => import('@/view/pages/master/GroupPoint/addGroupPoint.vue'),
            },
            {
              path: 'editgrouppoint/:id',
              name: 'Edit Group Point',
              component: () => import('@/view/pages/master/GroupPoint/editGroupPoint.vue'),
            },

            // Route Master Group Point
            {
              path: 'point',
              name: 'Point',
              component: () => import('@/view/pages/master/Point/Point.vue'),
            },
            {
              path: 'addpoint',
              name: 'Add Point',
              component: () => import('@/view/pages/master/Point/addPoint.vue'),
            },
            {
              path: 'editpoint/:id',
              name: 'Edit Point',
              component: () => import('@/view/pages/master/Point/editPoint.vue'),
            },

            // Route Master Inspection Plant
            {
              path: 'inspectionplant',
              name: 'Inspection Plant',
              component: () => import('@/view/pages/master/inspectionplant/inspectionPlant.vue'),
            },
            {
              path: 'addinspectionplant',
              name: 'Add Inspection Plant',
              component: () => import('@/view/pages/master/inspectionplant/addInspectionPlant.vue'),
            },
            {
              path: 'importinspectionplant',
              name: 'Import Inspection Plant',
              component: () =>
                import('@/view/pages/master/inspectionplant/importInspectionPlant.vue'),
            },
            {
              path: 'editinspectionplant/:id',
              name: 'Edit Inspection Plant',
              component: () =>
                import('@/view/pages/master/inspectionplant/editInspectionPlant.vue'),
            },
          ],
        },

        // Setting User
        {
          path: '/user',
          name: 'User',
          component: () => import('@/view/pages/settingusers/page.vue'),
          children: [
            // Route User Role
            {
              path: 'role',
              name: 'Role',
              component: () => import('@/view/pages/settingusers/user_role/UserRole.vue'),
            },
            {
              path: 'adduserrole',
              name: 'Add User Role',
              component: () => import('@/view/pages/settingusers/user_role/addUserRole.vue'),
            },
            {
              path: 'edituserrole/:id',
              name: 'Edit User Role',
              component: () => import('@/view/pages/settingusers/user_role/editUserRole.vue'),
            },

            // Route Users
            {
              path: 'users',
              name: 'Users',
              component: () => import('@/view/pages/settingusers/users/Users.vue'),
            },
            {
              path: 'addusers',
              name: 'Add Users',
              component: () => import('@/view/pages/settingusers/users/addUsers.vue'),
            },
            {
              path: 'editusers/:id',
              name: 'Edit Users',
              component: () => import('@/view/pages/settingusers/users/editUsers.vue'),
            },
          ],
        },
      ],
    },
    {
      path: '/report/detail_report/:id',
      name: 'Detail Report',
      component: () => import('@/view/pages/report/create/detailQr.vue'),
    },
    {
      path: '/',
      component: () => import('@/view/pages/auth/login_pages/Login-1'),
      children: [
        {
          name: 'login',
          path: '/login',
          component: () => import('@/view/pages/auth/login_pages/Login-1'),
        },
        {
          name: 'register',
          path: '/register',
          component: () => import('@/view/pages/auth/login_pages/Login-1'),
        },
      ],
    },
    {
      path: '*',
      redirect: '/404',
    },
    {
      // the 404 route, when none of the above matches
      path: '/404',
      name: '404',
      component: () => import('@/view/pages/error/Error-1.vue'),
    },
  ],
})
