import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from '@/core/services/store'
import ApiService from '@/core/services/api.service'
// import MockService from "@/core/mock/mock.service";
import { VERIFY_AUTH } from '@/core/services/store/auth.module'
import { RESET_LAYOUT_CONFIG } from '@/core/services/store/config.module'

Vue.config.productionTip = false

// Global 3rd party plugins
import 'popper.js'
import 'tooltip.js'
import PerfectScrollbar from 'perfect-scrollbar'
window.PerfectScrollbar = PerfectScrollbar
import ClipboardJS from 'clipboard'
window.ClipboardJS = ClipboardJS

// Vue 3rd party plugins
import i18n from '@/core/plugins/vue-i18n'
import vuetify from '@/core/plugins/vuetify'
import '@/core/plugins/portal-vue'
import '@/core/plugins/bootstrap-vue'
import '@/core/plugins/perfect-scrollbar'
import '@/core/plugins/inline-svg'
import '@/core/plugins/apexcharts'
import '@/core/plugins/treeselect'
import '@/core/plugins/metronic'
import '@mdi/font/css/materialdesignicons.css'
import '@/core/plugins/formvalidation'

import AxiosPlugin from 'vue-axios-cors'
Vue.use(AxiosPlugin)

import VueConfirmDialog from 'vue-confirm-dialog'
Vue.use(VueConfirmDialog)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
Vue.component('v-select', vSelect)

import VueMoment from 'vue-moment'
Vue.use(VueMoment)

import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)

import VueSuggestion from 'vue-suggestion'
Vue.use(VueSuggestion)

import VueHtmlToPaper from 'vue-html-to-paper'

const options = {
  name: '_blank',
  specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
  styles: [
    'https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css',
    'css/custom.css',
  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: false, // if false, the window will not close after printing
  windowTitle: window.document.title, // override the window title
}

Vue.use(VueHtmlToPaper, options)

import excel from 'vue-excel-export'

Vue.use(excel)

import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

import 'vue2-datepicker/locale/id'
Vue.use(DatePicker)

const swalOptions = {
  denyButtonColor: '#1BC5BD',
}
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
Vue.use(VueSweetalert2, swalOptions)

// API service init
ApiService.init()

// Remove this to disable mock API
// MockService.init();

router.beforeEach((to, from, next) => {
  // Ensure we checked auth before each page load.
  Promise.all([store.dispatch(VERIFY_AUTH)]).then(next)

  // reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG)

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0)
  }, 100)
})

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App),
}).$mount('#app')
