const ID_TOKEN_KEY = 'syspek_token'

export const getToken = () => {
  const itemStr = localStorage.getItem(ID_TOKEN_KEY)
  // if the item doesn't exist, return null
  if (!itemStr || itemStr.charAt(0) != '{') {
    return null
  }

  const item = JSON.parse(itemStr)
  // console.log(item);
  const now = new Date()
  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(ID_TOKEN_KEY)
    location.reload()
    // return null
  } else {
    var refresh = {
      value: item.value,
      // expiry: now.getTime() + 1800000,
      expiry: now.getTime() + 21600000, // 6*60*60*1000 = 6 Jam
    }
    // console.log(JSON.stringify(item));
    localStorage.setItem(ID_TOKEN_KEY, JSON.stringify(refresh))
  }
  return item.value
  // return window.localStorage.getItem(ID_TOKEN_KEY);
}

export const saveToken = token => {
  const now = new Date()

  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  var item = {
    value: token,
    // expiry: now.getTime() + 1800000,
    expiry: now.getTime() + 21600000, // 6*60*60*1000 = 6 Jam
  }
  // console.log(JSON.stringify(item));
  localStorage.setItem(ID_TOKEN_KEY, JSON.stringify(item))
}

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY)
}

export default { getToken, saveToken, destroyToken }
