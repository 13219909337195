import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import JwtService from '@/core/services/jwt.service'

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    Vue.use(VueAxios, axios)
    Vue.axios.defaults.baseURL =
      process.env.NODE_ENV === 'production'
        ? 'https://syspek.pupuk-kujang.co.id/api'
        : 'http://127.0.0.1:8000/api'
        // ? 'http://34.128.95.239/syspek/prd/api/public/api'
        // : 'http://localhost:8000/api'
        
  },

  urlApi() {
    return process.env.NODE_ENV === 'production'
      ? 'https://syspek.pupuk-kujang.co.id/api'
      : 'http://127.0.0.1:8000/api'
      // ? 'http://34.128.95.239/syspek/prd/api/public/api'
      // : 'http://localhost:8000/api'
  },

  urlStorage() {
    return process.env.NODE_ENV === 'production'
      ? 'http://syspek.pupuk-kujang.co.id/api/storage'
      : 'http://localhost:8000/storage'
      // ? 'https://syspek.pupuk-kujang.co.id/api/storage/app/public'
      // ? 'http://34.128.95.239/syspek/prd/api/public/storage'
      // : 'http://localhost:8000/storage'
  },

  headerAPi() {
    return {
      Accept: 'application/json',
      Authorization: 'Bearer ' + JwtService.getToken(),
    }
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    Vue.axios.defaults.headers.common['Authorization'] = `Bearer ${JwtService.getToken()}`
  },

  query(resource, params) {
    JwtService.getToken()
    return Vue.axios.get(resource, params).catch(error => {
      // console.log(error);
      throw new Error(`[KT] ApiService ${error}`)
    })
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource) {
    JwtService.getToken()
    return Vue.axios.get(`${resource}`).catch(function(error) {
      // console.log(error.response.status);
      if (error.response.status == 401) {
        JwtService.destroyToken()
        location.reload()
      }
      // throw new Error(`[KT] ApiService ${error}`);
    })
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    JwtService.getToken()
    // console.log(resource);
    return Vue.axios.post(`${resource}`, params).catch(error => {
      // console.log(error);
      throw new Error(`[RWV] ApiService ${error}`)
    })
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    JwtService.getToken()
    return Vue.axios.put(`${resource}/${slug}`, params)
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    JwtService.getToken()
    return Vue.axios.put(`${resource}`, params)
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    return Vue.axios.delete(resource).catch(error => {
      // console.log(error);
      throw new Error(`[RWV] ApiService ${error}`)
    })
  },
}

export default ApiService
